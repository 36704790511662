import { DeleteOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Button, Drawer, Popconfirm, Table as AntTable } from "antd";
import { ColumnsType } from "antd/es/table";
import * as React from "react";
import { FC, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import AvatarTile from "../../../../../../components/AvatarTile";
import FlexBox, { CustomWidthE } from "../../../../../../components/FlexBox";
import Panel from "../../../../../../components/Panel";
import PlaceholderLoader, {
  PlaceholdersTypeE,
} from "../../../../../../features/Placeholders/PlaceholderLoader";
import { getDateTime } from "../../../../../../helpers/date";
import { useTranslate } from "../../../../../../hooks/useTranslate";
import { rem } from "../../../../../../styling/theme";
import { removePartnershipMutation } from "../../../../data-access/mutation/removePartnershipMutation";
import { removePartnership } from "../../../../data-access/removePartnership";
import { CampaignT, PartnershipT } from "../../../../types";
import ApprovalRequestModal from "./ApprovalRequestModal";
import AutocollectInsights from "./AutocollectInsights";

const DeleteButton = styled(Button)({
  visibility: "hidden",
});

const StyledTableWrapper = styled(FlexBox)({
  width: "100%",

  ".ant-table-row": {
    "&:hover, &:focus-within": {
      [`${DeleteButton}`]: {
        visibility: "visible",
      },
    },
  },
});

interface DataType {
  key: string;
  partnership: PartnershipT;
}

export type PartnershipToRemoveT = {
  id: string;
  removed: boolean;
};

type TableT = {
  campaign: CampaignT;
  dataIsLoading: boolean;
  partnerships: PartnershipT[];
  emptyState: JSX.Element;
};

const Table: FC<TableT> = ({
  campaign,
  dataIsLoading,
  partnerships,
  emptyState,
}) => {
  const [partnershipForApproval, setPartnershipForApproval] = useState<
    PartnershipT | undefined
  >();

  const { t } = useTranslate("brands.campaign.partnerships");

  const columns: ColumnsType<DataType> = [
    {
      title: t("table.influencer"),
      dataIndex: "handle",
      render: (_, record) => {
        const { handle, network, display_name, avatar_url } =
          record.partnership;
        return (
          <FlexBox justifyContent="flex-start">
            <AvatarTile
              handle={handle}
              network={network}
              name={display_name ? display_name : handle}
              imageUrl={avatar_url}
            />
          </FlexBox>
        );
      },
      sorter: (a, b) =>
        a.partnership.handle.localeCompare(b.partnership.handle),
    },
    {
      title: t("table.outputs"),
      dataIndex: "outputs",
      render: (_, record) => record.partnership.media_groups_kinds,
    },
    {
      title: t("table.created_at"),
      dataIndex: "created_at",
      render: (_, record) =>
        getDateTime(record.partnership.created_at, "shortDate"),
      sorter: (a, b) =>
        a.partnership.created_at.localeCompare(b.partnership.created_at),
    },
    {
      title: t("table.autocollect"),
      dataIndex: "autocollect",
      render: (_, record) => (
        <AutocollectInsights
          campaign={campaign}
          partnership={record.partnership}
        />
      ),
    },
    {
      dataIndex: "actions",
      render: (_, record) => (
        <FlexBox justifyContent="flex-end" gap={rem(8)}>
          {record.partnership.network !== "tiktok" &&
            !record.partnership.insights && (
              <Button
                size="small"
                onClick={() => setPartnershipForApproval(record.partnership)}
              >
                {t("table.request")}
              </Button>
            )}
          <Popconfirm
            title={t("remove_modal.title")}
            description={t("remove_modal.question")}
            onConfirm={() => removePartnershipHandle(record.partnership.id)}
            okText={t("remove_modal.confirm")}
            cancelText={t("remove_modal.close")}
            okButtonProps={{ disabled: isLoading }}
          >
            <DeleteButton type="text" icon={<DeleteOutlined />} />
          </Popconfirm>
        </FlexBox>
      ),
    },
  ];

  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(removePartnership, {
    onSuccess: (_, variables) => {
      setTimeout(() => {
        removePartnershipMutation(
          queryClient,
          variables.partnershipId,
          campaign.id,
        );
      }, 300);
    },
  });

  const removePartnershipHandle = (id: string): void => {
    const data = { partnershipId: id, campaignId: campaign.id };

    mutate(data);
  };

  const getTableContent = (): JSX.Element => {
    if (partnerships.length === 0) return emptyState;

    if (dataIsLoading) {
      return (
        <FlexBox fullHeight={true} customWidth={CustomWidthE.full}>
          <PlaceholderLoader
            type={PlaceholdersTypeE.table_item}
            count={5}
            direction="column"
          />
        </FlexBox>
      );
    }

    const data: DataType[] = partnerships.map((partnership) => ({
      key: partnership.id,
      partnership: partnership,
    }));

    return (
      <StyledTableWrapper>
        <AntTable
          style={{ width: "100%" }}
          columns={columns}
          dataSource={data}
          pagination={false}
        />
      </StyledTableWrapper>
    );
  };

  return (
    <>
      <Panel flexDirection="column" position="relative">
        {getTableContent()}
      </Panel>

      <Drawer
        open={!!partnershipForApproval}
        title={t("approval_request_modal.title")}
        onClose={() => setPartnershipForApproval(undefined)}
      >
        <>
          {partnershipForApproval && (
            <ApprovalRequestModal
              campaign={campaign}
              partnership={partnershipForApproval}
              close={() => setPartnershipForApproval(undefined)}
            />
          )}
        </>
      </Drawer>
    </>
  );
};

export default Table;
