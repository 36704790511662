import styled from "@emotion/styled";
import { Select as AntSelect, SelectProps as AntSelectProps } from "antd";
import { omit } from "lodash";
import * as React from "react";
import { FC } from "react";
import FlexBox, { CustomWidthE } from "../components/FlexBox";
import { designToken } from "../helpers/antDesign";
import { rem } from "../styling/theme";

const Wrapper = styled(FlexBox)({
  alignItems: "stretch",
});

const AddonWrapper = styled(FlexBox)({
  backgroundColor: designToken.colorFillAlter,
  border: `1px solid ${designToken.colorBorder}`,
  borderRight: "none",
  borderRadius: `${rem(designToken.borderRadius)} 0 0 ${rem(
    designToken.borderRadius,
  )}`,
  padding: `0 ${rem(designToken.paddingSM)}`,
});

const StyledSelect = styled(AntSelect)<{ addon: boolean }>(({ addon }) => ({
  ".ant-select-selector": {
    borderRadius: addon
      ? `0 ${rem(designToken.borderRadius)} ${rem(designToken.borderRadius)} 0`
      : designToken.borderRadius,
  },
}));

interface SelectProps {
  addonBefore?: string;
}

const Select: FC<AntSelectProps & SelectProps> = (props) => {
  const addonBefore = props.addonBefore;
  const options = omit(props, ["addonBefore"]) as AntSelectProps;

  return (
    <Wrapper customWidth={CustomWidthE.full}>
      {addonBefore && <AddonWrapper>{addonBefore}</AddonWrapper>}
      <StyledSelect {...options} addon={!!addonBefore} />
    </Wrapper>
  );
};

export default Select;
