import { PlusOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Button, Tooltip } from "antd";
import * as React from "react";
import { FC, useContext, useEffect } from "react";
import BlueLink from "../../../components/BlueLink";
import FlexBox, { CustomWidthE } from "../../../components/FlexBox";
import Paragraph from "../../../components/Paragraph";
import { ApplicationContext } from "../../../context-providers/ApplicationContextProvider";
import { designToken } from "../../../helpers/antDesign";
import { useTranslate } from "../../../hooks/useTranslate";
import { rem } from "../../../styling/theme";
import { SubscriptionEntitlementFeaturesT } from "../../types";
import { SubscriptionContext } from "../context-providers/SubscriptionsContextProvider";

const HeaderWrapper = styled(FlexBox)({
  padding: `${rem(10)} ${rem(10)} ${rem(20)} ${rem(10)}`,
  gap: rem(16),
});

const Header = styled(FlexBox)({
  width: "100%",
  gap: rem(4),
});

type LimitHeaderT = {
  title?: string;
  onClickHandle: (value: boolean) => void;
  submitTitle: string;
  entitlementFeature: SubscriptionEntitlementFeaturesT;
  disableCreate?: boolean;
  disableCreateMessage?: string;
  contentPosition?: "left" | "right";
};

const LimitHeader: FC<LimitHeaderT> = ({
  title,
  onClickHandle,
  submitTitle,
  entitlementFeature,
  disableCreate = false,
  disableCreateMessage,
  contentPosition = "left",
}) => {
  const { entitlementValue, session } = useContext(ApplicationContext);
  const { openPortal, getFeatureUsage, setSubscription } =
    useContext(SubscriptionContext);
  const { t } = useTranslate("brands.subscriptions");

  const subscription = session?.subscription || undefined;

  const usage = getFeatureUsage(session, entitlementFeature);
  const monthCount = (usage && usage.used) || 0;
  const limit = (entitlementValue(entitlementFeature) as number) || 0;
  const limitReached = monthCount >= limit;

  useEffect(() => {
    limitReached && setSubscription(subscription);
  }, [subscription]);

  return (
    <HeaderWrapper
      customWidth={CustomWidthE.full}
      justifyContent="space-between"
      flexDirection="row"
    >
      <Header
        justifyContent={contentPosition === "left" ? "flex-start" : "flex-end"}
      >
        {title && <Paragraph paragraph={title} fontWeight={600} />}
        <FlexBox gap={rem(12)}>
          <FlexBox gap={rem(4)}>
            <Paragraph
              paragraph={monthCount.toString()}
              color={
                limitReached
                  ? designToken.colorError
                  : designToken.colorTextTertiary
              }
            />
            <Paragraph
              paragraph={`/ ${limit}`}
              color={designToken.colorTextTertiary}
            />
          </FlexBox>
          {limitReached && (
            <BlueLink
              text={t("buy_addon")}
              type="grey"
              marginTop="0"
              onClick={() => openPortal("edit_subscription")}
            />
          )}
        </FlexBox>
      </Header>
      <Tooltip title={disableCreate && disableCreateMessage}>
        <Button
          onClick={() => onClickHandle(true)}
          disabled={limitReached || disableCreate}
          icon={<PlusOutlined />}
          type="primary"
        >
          {submitTitle}
        </Button>
      </Tooltip>
    </HeaderWrapper>
  );
};

export default LimitHeader;
