import { ConfigProvider } from "antd";
import cs from "antd/locale/cs_CZ";
import en from "antd/locale/en_GB";
import I18n from "i18n-js";
import * as React from "react";
import { RouterProvider } from "react-router-dom";
import { defaults } from "../../helpers/antDesign";
import { SubscriptionContextProvider } from "./context-providers/SubscriptionsContextProvider";
import { ToastContextProvider } from "./context-providers/ToastContextProvider";
import { router } from "./router";

const CompanyRoot: React.FC = () => {
  return (
    <>
      <ConfigProvider locale={I18n.locale === "cs" ? cs : en} theme={defaults}>
        <ToastContextProvider>
          <SubscriptionContextProvider>
            <RouterProvider router={router} />
          </SubscriptionContextProvider>
        </ToastContextProvider>
      </ConfigProvider>
    </>
  );
};

export default CompanyRoot;
