import {
  LogoutOutlined,
  NotificationOutlined,
  RiseOutlined,
  SettingOutlined,
  SyncOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import styled from "@emotion/styled";
import { Badge } from "antd";
import * as React from "react";
import { FC, useContext, useState } from "react";
import FlexBox, { CustomWidthE } from "../../../../components/FlexBox";
import NavigationContainer from "../../../../components/NavigationContainer";
import { ApplicationContext } from "../../../../context-providers/ApplicationContextProvider";
import { designToken } from "../../../../helpers/antDesign";
import { ApiRoutesE, getApiUrl } from "../../../../helpers/routes";
import { useTranslate } from "../../../../hooks/useTranslate";
import { rem, theme } from "../../../../styling/theme";
import NavigationItem from "../../../../ui/NavigationItem";
import { NavigationKeyE, useRouterNavigate } from "../../router";
import { reloadPageTo } from "./helpers";

const TextWrapper = styled(FlexBox)<{ open: boolean }>(({ open }) => ({
  visibility: open ? "visible" : "hidden",
  cursor: "pointer",
}));

const MenuItemsWrapper = styled(FlexBox)({
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100%",
  width: "100%",
});

const NavigationGroup = styled(FlexBox)({
  flexDirection: "column",
  width: "100%",
  gap: rem(4),
});

const LogoutItem = styled.a({
  width: "100%",
  paddingLeft: rem(12),
  height: rem(40),
  borderRadius: rem(8),

  ":hover": {
    backgroundColor: theme.color.backgroundColor,
  },
});

const LogoutInnerWrapper = styled(FlexBox)({
  justifyContent: "flex-start",
  gap: rem(8),
  width: "100%",
  height: "100%",
  cursor: "pointer",
});

type NavigationT = {
  standalone?: boolean;
};

const Navigation: FC<NavigationT> = ({ standalone = false }) => {
  const [openMenu, setOpenMenu] = useState<boolean>(false);

  const { session } = useContext(ApplicationContext);
  const isAdmin = session?.roles && session.roles.includes("admin");

  const navigate = standalone ? reloadPageTo : useRouterNavigate();

  const path = window.location.pathname;

  const { t } = useTranslate("menu");

  const handleNavigationItemOnClick = (key: NavigationKeyE): void => {
    navigate(key);

    setOpenMenu(false);
  };

  const handleProfileOnClick = (): void => {
    window.location.href = getApiUrl(ApiRoutesE.PROFILE_EDIT);
  };

  return (
    <NavigationContainer
      navigateHome={() => navigate(NavigationKeyE.Database)}
      openMenu={openMenu}
      setOpenMenu={setOpenMenu}
    >
      <MenuItemsWrapper>
        <NavigationGroup
          flexDirection="column"
          gap={rem(4)}
          customWidth={CustomWidthE.full}
        >
          <NavigationItem
            onClick={() => handleNavigationItemOnClick(NavigationKeyE.Database)}
            active={path.match(NavigationKeyE.Database) !== null}
            icon={<TeamOutlined />}
          >
            <TextWrapper open={openMenu}>{t("database")}</TextWrapper>
          </NavigationItem>
          <NavigationItem
            onClick={() =>
              handleNavigationItemOnClick(NavigationKeyE.OverlapAnalyses)
            }
            active={path.match(NavigationKeyE.OverlapAnalyses) !== null}
            icon={<SyncOutlined />}
          >
            <TextWrapper open={openMenu}>{t("overlap_analyses")}</TextWrapper>
          </NavigationItem>
          <NavigationItem
            onClick={() =>
              handleNavigationItemOnClick(NavigationKeyE.CompetitiveAnalyses)
            }
            active={path.match(NavigationKeyE.CompetitiveAnalyses) !== null}
            icon={<RiseOutlined />}
          >
            <TextWrapper open={openMenu}>
              {t("competitive_analyses")}
            </TextWrapper>
          </NavigationItem>
          <NavigationItem
            onClick={() =>
              handleNavigationItemOnClick(NavigationKeyE.Campaigns)
            }
            active={path.match("campaigns") !== null}
            icon={<NotificationOutlined />}
          >
            <Badge
              count={"Beta"}
              offset={[22, 0]}
              style={{
                color: designToken.colorPrimary,
                backgroundColor: designToken.colorPrimaryBg,
                borderColor: designToken.colorPrimaryBg,
              }}
            >
              <TextWrapper open={openMenu}>{t("campaigns")}</TextWrapper>
            </Badge>
          </NavigationItem>
        </NavigationGroup>
        <NavigationGroup>
          {isAdmin && (
            <LogoutItem href="/admin/users">
              <LogoutInnerWrapper>
                <SettingOutlined />
                <TextWrapper open={openMenu}>{t("admin")}</TextWrapper>
              </LogoutInnerWrapper>
            </LogoutItem>
          )}
          <NavigationItem
            onClick={handleProfileOnClick}
            active={
              standalone === true ||
              path.match(NavigationKeyE.Subscription) !== null
            }
            icon={<UserOutlined />}
          >
            <TextWrapper open={openMenu}>{t("profile")}</TextWrapper>
          </NavigationItem>
          <LogoutItem href="/users/logout" data-method="delete">
            <LogoutInnerWrapper>
              <LogoutOutlined />
              <TextWrapper open={openMenu}>{t("logout")}</TextWrapper>
            </LogoutInnerWrapper>
          </LogoutItem>
        </NavigationGroup>
      </MenuItemsWrapper>
    </NavigationContainer>
  );
};

export default Navigation;
