import { PlusOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { Button, Form } from "antd";
import * as React from "react";
import { FC, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Container from "../../../../components/Container";
import Drawer from "../../../../components/Drawer";
import FlexBox, { CustomWidthE } from "../../../../components/FlexBox";
import Paragraph from "../../../../components/Paragraph";
import { QueryKeys } from "../../../../helpers/queryKeys";
import { useTranslate } from "../../../../hooks/useTranslate";
import { rem } from "../../../../styling/theme";
import { CampaignsEmptyStatePlaceholder } from "../../../../ui/icons/PlaceHolders";
import { createCampaign } from "../../data-access/createCampaign";
import { getCampaigns } from "../../data-access/getCampaigns";
import { updateCampaignsMutation } from "../../data-access/mutation/updateCampaignsMutation";
import AddNewModal, { FormFieldsT } from "./AddNewModal";
import Table from "./Table";

const EmptyStateWrapper = styled(FlexBox)({
  flexDirection: "column",
  width: "50%",
  padding: rem(50),
  gap: rem(24),
});

const CampaignsContainer: FC = () => {
  const [addNew, setAddNew] = useState<boolean>(false);
  const [newBrandInput, setNewBrandInput] = useState<boolean>(false);

  const [form] = Form.useForm<FormFieldsT>();
  const { t } = useTranslate("brands.campaigns");

  const {
    data: campaigns,
    isLoading,
    isRefetching,
    refetch,
  } = useQuery(QueryKeys.CAMPAIGNS, () => getCampaigns());

  const queryClient = useQueryClient();
  const { mutate: create } = useMutation(
    () => {
      const clientParams = form.getFieldValue("brandName")
        ? { client_name: form.getFieldValue("brandName") }
        : { client_id: form.getFieldValue("brandId") };
      const params = {
        campaign: {
          title: form.getFieldValue("title"),
          from: form.getFieldValue("date")[0],
          to: form.getFieldValue("date")[1],
          instagram_mentions: form.getFieldValue("instagram_mentions"),
          tiktok_mentions: form.getFieldValue("tiktok_mentions"),
          hashtags: form.getFieldValue("hashtags"),
          ...clientParams,
        },
      };
      return createCampaign(params);
    },
    {
      onSuccess: (data) => {
        updateCampaignsMutation(queryClient, data);
        onCloseHandle();
      },
    },
  );

  const onCloseHandle = (): void => {
    setAddNew(false);
    form.resetFields();
    setNewBrandInput(false);
  };

  return (
    <Container>
      <FlexBox flexDirection="column" gap={rem(24)}>
        <FlexBox
          customWidth={CustomWidthE.full}
          justifyContent="space-between"
          alignItems="center"
        >
          <Paragraph paragraph={t("title")} fontWeight={600} />
          {campaigns && campaigns.length > 0 && (
            <Button
              type="primary"
              onClick={() => setAddNew(true)}
              icon={<PlusOutlined />}
            >
              {t("new")}
            </Button>
          )}
        </FlexBox>
        {campaigns && campaigns.length > 0 ? (
          <Table
            campaigns={campaigns}
            dataIsLoading={isLoading || isRefetching}
            refetch={refetch}
          />
        ) : (
          !isLoading && (
            <EmptyStateWrapper>
              <CampaignsEmptyStatePlaceholder />
              <Paragraph paragraph={t("form.empty_title")} fontWeight={600} />
              <AddNewModal
                form={form}
                newBrandInput={newBrandInput}
                setNewBrandInput={setNewBrandInput}
                onFinishHandle={create}
              />
              <Button type="primary" onClick={() => form.submit()}>
                {t("form.submit")}
              </Button>
            </EmptyStateWrapper>
          )
        )}
      </FlexBox>

      <Drawer
        open={addNew}
        title={t("form.header")}
        onClose={onCloseHandle}
        onConfirm={{ label: t("form.submit"), handle: () => form.submit() }}
      >
        <AddNewModal
          form={form}
          newBrandInput={newBrandInput}
          setNewBrandInput={setNewBrandInput}
          onFinishHandle={create}
        />
      </Drawer>
    </Container>
  );
};

export default CampaignsContainer;
