export enum ApiRoutesE {
  APPROVAL_REQUESTS = "APPROVAL_REQUESTS",
  APPROVAL_REQUESTS_AUTHORIZATION_EMAIL = "APPROVAL_REQUESTS_AUTHORIZATION_EMAIL",
  APPROVAL_REQUESTS_AUTHORIZATION_NEW = "APPROVAL_REQUESTS_AUTHORIZATION_NEW",
  APPROVAL_REQUESTS_INFLUENCER = "APPROVAL_REQUESTS_INFLUENCER",
  APPROVAL_REQUESTS_ITEM = "APPROVAL_REQUESTS_ITEM",
  APPROVAL_REQUESTS_SEND_NOTIFICATION = "APPROVAL_REQUESTS_SEND_NOTIFICATION",
  APPROVALS = "APPROVALS",
  CAMPAIGN_APPROVAL_REQUESTS = "CAMPAIGN_APPROVAL_REQUESTS",
  CAMPAIGN_MEDIA_GROUPS = "CAMPAIGN_MEDIA_GROUPS",
  CAMPAIGN_MEDIA_GROUPS_HIDE = "CAMPAIGN_MEDIA_GROUPS_HIDE",
  CAMPAIGN_MEDIA_GROUPS_MERGE = "CAMPAIGN_MEDIA_GROUPS_MERGE",
  CAMPAIGN_PARTNERSHIPS = "CAMPAIGN_PARTNERSHIPS",
  CAMPAIGN_PARTNERSHIP = "CAMPAIGN_PARTNERSHIP",
  CAMPAIGNS_PARTNERSHIP = "CAMPAIGNS_PARTNERSHIP",
  CAMPAIGNS = "CAMPAIGNS",
  CAMPAIGNS_ANALYTICS = "CAMPAIGNS_ANALYTICS",
  CAMPAIGN = "CAMPAIGN",
  CAMPAIGN_EXPORT = "CAMPAIGN_EXPORT",
  CAMPAIGN_HASHTAGS = "CAMPAIGN_HASHTAGS",
  CAMPAIGNS_PPTX = "CAMPAIGNS_PPTX",
  CLIENTS = "CLIENTS",
  CLIENTS_ITEM = "CLIENTS_ITEM",
  COMPETITIVE_ANALYSES = "COMPETITIVE_ANALYSES",
  COMPETITIVE_ANALYSES_DETAIL = "COMPETITIVE_ANALYSES_DETAIL",
  COMPETITIVE_ANALYSES_OUTPUTS = "COMPETITIVE_ANALYSES_OUTPUTS",
  CURRENT_USER = "CURRENT_USER",
  IG_HANDLES = "IG_HANDLES",
  LISTS = "LISTS",
  LISTS_DETAIL = "LISTS_DETAIL",
  MEDIA_DELETE = "MEDIA_DELETE",
  MEDIA_FILE = "MEDIA_FILE",
  MEDIA_GROUPS = "MEDIA_GROUPS",
  MEDIAS = "MEDIAS",
  MEDIAS_ITEM = "MEDIAS_ITEM",
  MENTIONS = "MENTIONS",
  NETWORK_PROFILE_BRAND_AFFINITY = "NETWORK_PROFILE_BRAND_AFFINITY",
  NETWORK_PROFILE_CONTENT_OUTPUTS = "NETWORK_PROFILE_CONTENT_OUTPUTS",
  NETWORK_PROFILE_DETAIL = "NETWORK_PROFILE_DETAIL",
  NETWORK_PROFILE_SUGGESTIONS = "NETWORK_PROFILE_SUGGESTIONS",
  NETWORK_PROFILES = "NETWORK_PROFILES",
  NETWORK_PROFILES_CONTACT = "NETWORK_PROFILES_CONTACT",
  NETWORK_PROFILES_CONTACT_CONFIRM_ACCOUNT = "NETWORK_PROFILES_CONTACT_CONFIRM_ACCOUNT",
  NETWORK_PROFILES_CONTACT_RESOLVE_CONFLICT = "NETWORK_PROFILES_CONTACT_RESOLVE_CONFLICT",
  NETWORK_PROFILES_CONTACT_NOTE = "NETWORK_PROFILES_CONTACT_NOTE",
  NETWORK_PROFILES_CONTACT_NOTES = "NETWORK_PROFILES_CONTACT_NOTES",
  NETWORK_PROFILES_FILTER = "NETWORK_PROFILES_FILTER",
  OUTPUTS = "OUTPUTS",
  OVERLAP_ANALYSES = "OVERLAP_ANALYSES",
  OVERLAP_ANALYSIS_DETAIL = "OVERLAP_ANALYSIS_DETAIL",
  PARTNERSHIPS = "PARTNERSHIPS",
  PARTNERSHIPS_ITEM = "PARTNERSHIPS_ITEM",
  PARTNERSHIPS_ITEM_ACTIVATE = "PARTNERSHIPS_ITEM_ACTIVATE",
  PARTNERSHIPS_ITEM_PAUSE = "PARTNERSHIPS_ITEM_PAUSE",
  PROFILE_EDIT = "PROFILE_EDIT",
  SESSION = "SESSION",
  MANAGE_USERS = "MANAGE_USERS",
  SESSION_DELETE_AFTER_PATH = "SESSION_DELETE_AFTER_PATH",
  SUBSCRIPTIONS_PLANS = "SUBSCRIPTIONS_PLANS",
  SUBSCRIPTIONS_PORTAL = "SUBSCRIPTIONS_PORTAL",
  SUBSCRIPTIONS_PROPOSAL = "SUBSCRIPTIONS_PROPOSAL",
  SUBSCRIPTIONS_PROPOSAL_ACCEPT = "SUBSCRIPTIONS_PROPOSAL_ACCEPT",
  SUBSCRIPTIONS_PROPOSAL_PREPARE = "SUBSCRIPTIONS_PROPOSAL_PREPARE",
  SUBSCRIPTIONS_SUBSCRIPTIONS = "SUBSCRIPTIONS_SUBSCRIPTIONS",
  SUBSCRIPTIONS_SUBSCRIPTIONS_NEW = "SUBSCRIPTIONS_SUBSCRIPTIONS_NEW",
  SUBSCRIPTIONS_SYNC = "SUBSCRIPTIONS_SYNC",
  TAGS = "TAGS",
  USERS = "USERS",
  USERS_DETAIL = "USERS_DETAIL",
  USERS_REINVITE = "USERS_REINVITE",
  UPLOADS_NEW = "UPLOADS_NEW",

  NOTIFICATIONS = "NOTIFICATIONS",
  NOTIFICATION_READ = "NOTIFICATION_READ",
  NOTIFICATION_READ_ALL = "NOTIFICATION_READ_ALL",
}

type getApiUrlT = (
  url: ApiRoutesE,
  id?: number | string,
  parentId?: number | string,
) => string;
export const getApiUrl: getApiUrlT = (url, id, parentId) => {
  if (url === ApiRoutesE.SESSION) {
    return "/api/v1/session";
  }

  if (url === ApiRoutesE.SESSION_DELETE_AFTER_PATH) {
    return "/users/login?type=brand";
  }

  if (url === ApiRoutesE.PROFILE_EDIT) {
    return "/profile/edit";
  }

  if (url === ApiRoutesE.MANAGE_USERS) {
    return "/brands/users";
  }

  if (url === ApiRoutesE.UPLOADS_NEW) {
    return "/api/v1/uploads/new/";
  }

  if (url === ApiRoutesE.CLIENTS) {
    return "/api/v1/brands/clients";
  }

  if (url === ApiRoutesE.CLIENTS_ITEM && id) {
    return `/api/v1/brands/clients/${id}`;
  }

  if (url === ApiRoutesE.PARTNERSHIPS) {
    return "/api/v1/brands/partnerships";
  }

  if (url === ApiRoutesE.PARTNERSHIPS_ITEM && id) {
    return `/api/v1/brands/partnerships/${id}`;
  }

  if (url === ApiRoutesE.PARTNERSHIPS_ITEM_ACTIVATE && id) {
    return `/api/v1/brands/partnerships/${id}/activate`;
  }

  if (url === ApiRoutesE.PARTNERSHIPS_ITEM_PAUSE && id) {
    return `/api/v1/brands/partnerships/${id}/pause`;
  }

  if (url === ApiRoutesE.MEDIA_GROUPS) {
    return `/api/v1/brands/media_groups`;
  }

  if (url === ApiRoutesE.MEDIAS) {
    return `/api/v1/brands/medias`;
  }

  if (url === ApiRoutesE.MEDIA_DELETE) {
    return `/api/v1/brands/medias/${id}`;
  }

  if (url === ApiRoutesE.MEDIAS_ITEM && id) {
    return `/api/v1/brands/medias/${id}`;
  }

  if (url === ApiRoutesE.MEDIA_FILE && id) {
    return `/api/v1/brands/media_files/${id}`;
  }

  if (url === ApiRoutesE.CAMPAIGN_HASHTAGS && id) {
    return `/api/v1/brands/campaigns/${id}/hashtags`;
  }

  if (url === ApiRoutesE.MENTIONS) {
    return `/api/v1/brands/mentions`;
  }

  if (url === ApiRoutesE.CAMPAIGNS) {
    return "/api/v1/brands/campaigns";
  }

  if (url === ApiRoutesE.CAMPAIGN && id) {
    return `/api/v1/brands/campaigns/${id}`;
  }

  if (url === ApiRoutesE.CAMPAIGN_EXPORT && id) {
    return `/api/v1/brands/campaigns/${id}/export`;
  }

  if (url === ApiRoutesE.CAMPAIGNS_PPTX && id) {
    return `/api/v1/brands/campaigns/${id}/pptx`;
  }

  if (url === ApiRoutesE.CAMPAIGNS_ANALYTICS && id) {
    return `/api/v1/brands/campaigns/${id}/analytics`;
  }

  if (url === ApiRoutesE.CAMPAIGN_PARTNERSHIPS && id) {
    return `/api/v1/brands/campaigns/${id}/partnerships`;
  }

  if (url === ApiRoutesE.CAMPAIGNS_PARTNERSHIP && id && parentId) {
    return `/api/v1/brands/campaigns/${parentId}/partnerships/${id}`;
  }

  if (url === ApiRoutesE.CAMPAIGN_MEDIA_GROUPS && id) {
    return `/api/v1/brands/campaigns/${id}/media_groups`;
  }

  if (url === ApiRoutesE.CAMPAIGN_MEDIA_GROUPS_HIDE && id && parentId) {
    return `/api/v1/brands/campaigns/${parentId}/media_groups/${id}/hide`;
  }

  if (url === ApiRoutesE.CAMPAIGN_MEDIA_GROUPS_MERGE && id) {
    return `/api/v1/brands/campaigns/${id}/media_groups/merge`;
  }

  if (url === ApiRoutesE.NETWORK_PROFILES) {
    return `/api/v1/brands/network_profiles`;
  }

  if (url === ApiRoutesE.NETWORK_PROFILE_DETAIL && id) {
    return `/api/v1/brands/network_profiles/${id}`;
  }

  if (url === ApiRoutesE.NETWORK_PROFILE_SUGGESTIONS) {
    return `/api/v1/network_profiles`;
  }

  if (url === ApiRoutesE.NETWORK_PROFILE_BRAND_AFFINITY && id) {
    return `/api/v1/brands/network_profiles/${id}/brand_affinity`;
  }

  if (url === ApiRoutesE.NETWORK_PROFILE_CONTENT_OUTPUTS) {
    return `/api/v1/brands/network_profiles/content_outputs`;
  }
  if (url === ApiRoutesE.NETWORK_PROFILES_CONTACT && id) {
    return `/api/v1/brands/network_profiles/${id}/contact`;
  }

  if (url === ApiRoutesE.NETWORK_PROFILES_CONTACT_NOTE && id && parentId) {
    return `/api/v1/brands/network_profiles/${parentId}/contact/notes/${id}`;
  }

  if (url === ApiRoutesE.NETWORK_PROFILES_CONTACT_NOTES && id) {
    return `/api/v1/brands/network_profiles/${id}/contact/notes`;
  }

  if (url === ApiRoutesE.NETWORK_PROFILES_CONTACT_CONFIRM_ACCOUNT && id) {
    return `/api/v1/brands/network_profiles/${id}/contact/confirm_account`;
  }

  if (url === ApiRoutesE.NETWORK_PROFILES_CONTACT_RESOLVE_CONFLICT && id) {
    return `/api/v1/brands/network_profiles/${id}/contact/resolve_conflict`;
  }

  if (url === ApiRoutesE.NETWORK_PROFILES_FILTER) {
    return `/api/v1/brands/network_profiles/filter`;
  }

  if (url === ApiRoutesE.COMPETITIVE_ANALYSES) {
    return `/api/v1/brands/competitive_analyses`;
  }

  if (url === ApiRoutesE.COMPETITIVE_ANALYSES_DETAIL && id) {
    return `/api/v1/brands/competitive_analyses/${id}`;
  }

  if (url === ApiRoutesE.COMPETITIVE_ANALYSES_OUTPUTS && id) {
    return `/api/v1/brands/competitive_analyses/${id}/outputs`;
  }

  if (url === ApiRoutesE.CURRENT_USER) {
    return `/api/v1/current_user`;
  }

  if (url === ApiRoutesE.OUTPUTS) {
    return `/api/v1/brands/outputs`;
  }

  if (url === ApiRoutesE.OVERLAP_ANALYSES) {
    return `/api/v1/brands/overlap_analyses`;
  }

  if (url === ApiRoutesE.OVERLAP_ANALYSIS_DETAIL && id) {
    return `/api/v1/brands/overlap_analyses/${id}`;
  }

  if (url === ApiRoutesE.APPROVAL_REQUESTS) {
    return `/api/v1/brands/approval_requests`;
  }

  if (url === ApiRoutesE.CAMPAIGN_APPROVAL_REQUESTS && id) {
    return `/api/v1/brands/campaigns/${id}/approval_requests`;
  }

  if (url === ApiRoutesE.APPROVAL_REQUESTS_SEND_NOTIFICATION && id) {
    return `/api/v1/brands/approval_requests/${id}/send_notification`;
  }

  if (url === ApiRoutesE.SUBSCRIPTIONS_PLANS) {
    return `/api/v1/subscriptions/plans`;
  }

  if (url === ApiRoutesE.SUBSCRIPTIONS_SUBSCRIPTIONS_NEW) {
    return `/api/v1/subscriptions/subscriptions/new`;
  }

  if (url === ApiRoutesE.SUBSCRIPTIONS_SUBSCRIPTIONS) {
    return `/api/v1/subscriptions/subscriptions`;
  }

  if (url === ApiRoutesE.SUBSCRIPTIONS_PORTAL) {
    return `/api/v1/subscriptions/subscriptions/portal`;
  }

  if (url === ApiRoutesE.SUBSCRIPTIONS_PROPOSAL) {
    return `/api/v1/subscriptions/proposal`;
  }

  if (url === ApiRoutesE.SUBSCRIPTIONS_PROPOSAL_ACCEPT) {
    return `/api/v1/subscriptions/proposal/accept`;
  }

  if (url === ApiRoutesE.SUBSCRIPTIONS_PROPOSAL_PREPARE) {
    return `/api/v1/subscriptions/proposal/prepare_checkout`;
  }

  if (url === ApiRoutesE.SUBSCRIPTIONS_SYNC) {
    return `/api/v1/subscriptions/subscriptions/sync`;
  }

  if (url === ApiRoutesE.APPROVAL_REQUESTS_ITEM && id) {
    return `/api/v1/influencers/approval_requests/${id}`;
  }

  if (url === ApiRoutesE.APPROVAL_REQUESTS_INFLUENCER && id) {
    return `/api/v1/influencers/approval_requests/${id}`;
  }

  if (url === ApiRoutesE.APPROVAL_REQUESTS_AUTHORIZATION_NEW) {
    return `/api/v1/influencers/approval_requests/authorization/new`;
  }

  if (url === ApiRoutesE.APPROVAL_REQUESTS_AUTHORIZATION_EMAIL) {
    return `/api/v1/influencers/approval_requests/authorization/finalize_email`;
  }

  if (url === ApiRoutesE.APPROVALS) {
    return `/api/v1/influencers/approvals`;
  }

  if (url === ApiRoutesE.NOTIFICATIONS) {
    return `/api/v1/notifications`;
  }

  if (url === ApiRoutesE.NOTIFICATION_READ && id) {
    return `/api/v1/notifications/${id}/read`;
  }

  if (url === ApiRoutesE.NOTIFICATION_READ_ALL) {
    return `/api/v1/notifications/read_all`;
  }

  if (url === ApiRoutesE.IG_HANDLES) {
    return `/api/v1/brands/ig_handles`;
  }

  if (url === ApiRoutesE.LISTS) {
    return `/api/v1/brands/lists`;
  }

  if (url === ApiRoutesE.LISTS_DETAIL && id) {
    return `/api/v1/brands/lists/${id}`;
  }

  if (url === ApiRoutesE.TAGS) {
    return `/api/v1/brands/tags`;
  }

  if (url === ApiRoutesE.USERS) {
    return `/api/v1/brands/users`;
  }

  if (url === ApiRoutesE.USERS_DETAIL && id) {
    return `/api/v1/brands/users/${id}`;
  }

  if (url === ApiRoutesE.USERS_REINVITE && id) {
    return `/api/v1/brands/users/${id}/reinvite`;
  }

  return "/";
};
